<template>
  <div class="container integrity" v-loading="loading">
    <div class="top">
      <div class="name">{{ details.integrityTitle }}</div>

      <!-- <div class="tag_style cursorP" @click="toCompanyDetail">查看企业详情</div> -->
    </div>

    <div class="table">
      <div class="table_cell">
        <div class="label">评价名称</div>
        <div class="value">
          {{ details.integrityTitle | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">评价详细</div>
        <div class="value">
          {{ details.integrityContent | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">所属企业</div>
        <div class="value link" @click="toCompanyDetail">
          {{ details.companyName | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">给予单位</div>
        <div class="value">
          {{ details.integrityPublish | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">相关人员</div>
        <div class="value">
          {{ details.integrityPerson | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">评价时间</div>
        <div class="value">
          {{ details.integrityTime | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">信息来源</div>
        <div class="value">
          <div v-if="details.integrityUrl">
            <span class="vip" @click="linkToUrl(details.integrityUrl)">
              <!-- {{ details.integritySource }} -->
              查看来源
            </span>
          </div>
          <span v-else>--</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* 诚信详情 */
import { getIntegrityDetails } from "@/api/itemizedQuery";

export default {
  data() {
    return {
      id: "",
      details: { integrityUrl: "" },
      loading: false,
    };
  },

  methods: {
    /* 获取数据 */
    async getDetails() {
      this.loading = true;
      const params = {
        id: this.id,
      };
      const { data } = await getIntegrityDetails(params);
      this.details = data;
      this.loading = false;
    },

    /* 操作 */
    // 跳转到网页
    linkToUrl(url) {
      if (url) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
      } else {
        this.$message.warning("暂无跳转地址");
      }
    },
    // 跳转到企业详情页面
    toCompanyDetail() {
      let id = this.details.companyId;
      if (id) {
        this.$openNewWindow({
          name: "companyDetails",
          params: { name: "诚信信息" },
          query: {
            id,
          },
        });
      } else {
        this.$message.warning("暂无企业信息！");
      }
    },

    /* 初始化 */
    __init__() {
      this.id = this.$route.params.id;
      this.getDetails();
    },
  },

  created() {
    this.__init__();
  },

  activated() {
    // this.__init__();
  },
};
</script>

<style lang="less" scoped>
.integrity {
  padding: 20px;
  background-color: #fff;
  min-height: calc(100vh - 340px);

  .top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .name {
      font-size: 20px;
      font-weight: bold;
      color: #333;
      margin-right: 20px;
      flex: 1;
    }
  }
}
</style>
